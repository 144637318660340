<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <!-- title card -->

        <Card>
          <template v-slot:body>
            <b-tabs content-class="mt-3">
              <b-tab title="Rekam Medis" active> 
                <MedicalRecords />
              </b-tab>
              <b-tab title="Dokter">
                <Doctor />
              </b-tab>
              <b-tab title="Ruangan">
                <Room />
              </b-tab>
              <b-tab title="Gedung">
                <Buildings />
              </b-tab>
              <b-tab title="Departmen">
                <Departemens />
              </b-tab>
            </b-tabs>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MedicalRecords from "@/component/satu-sehat/MedicalRecords.vue";
import Departemens from "@/component/satu-sehat/Departemens.vue";
import Buildings from "@/component/satu-sehat/Buildings.vue";
import Room from "@/component/satu-sehat/Room.vue";
import Doctor from "@/component/satu-sehat/Doctor.vue";
import ApiService from "@/core/services/api.service.js";

import {
  setSsLocationDefault,
} from "@/core/services/jwt.service.js";

export default {
  components: {
    Card,
    MedicalRecords,
    Departemens,
    Buildings,
    Room,
    Doctor,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Satu Sehat" },
      { title: "Posting" },
    ]);

    ApiService.get("satu-sehat-settings/1").then((response) => {
      let access_token = response.data.data;
      setSsLocationDefault(access_token.location_default)
    })
  },
};
</script>

<style>
</style>